<template>
  <v-container>
    <BaseTitlePage class="mb-0" />

    <v-row dense>
      <v-col cols="12">
        <v-tabs
          show-arrows
          :vertical="checkMobile"
          class="b-tab-vertical"
          v-model="tab"
        >
          <template v-for="(header, index) in headers">
            <v-tab
              class="b-tab-vertical--tab"
              :href="`#${header.href}`"
              :key="index"
              v-if="header.visible"
            >
              <v-icon left :size="20" v-if="checkMobile">
                {{ header.icon }}
              </v-icon>
              {{ header.title }}
            </v-tab>
          </template>

          <v-tabs-items v-model="tab" class="b-tab-vertical--items">
            <template v-for="(content, index) in headers">
              <v-tab-item
                v-if="content.visible"
                :value="content.href"
                :key="index"
              >
                <component
                  v-if="content.href === tab"
                  :is="content.component"
                  :implementationId="$route.params.id"
                  @back="back()"
                />
              </v-tab-item>
            </template>
          </v-tabs-items>
        </v-tabs>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { checkPermission } from '@/helpers/permission';

export default {
  components: {
    PageDocuments: () =>
      import('@/components/pages/implementation/page-documents'),
    PageLifeCapture: () =>
      import('@/components/pages/implementation/page-life-capture'),
    PageHistory: () => import('@/components/pages/implementation/page-history'),
    PageInvoice: () => import('@/components/pages/implementation/page-invoice'),
    PagePlanCards: () =>
      import('@/components/pages/implementation/page-plan-cards'),
    PageFinish: () => import('@/components/pages/implementation/page-finish')
  },

  data: () => ({
    tab: null
  }),

  methods: {
    back() {
      this.$router.push({ name: 'implantacoes' });
    }
  },

  computed: {
    newSituation() {
      const { situation } = this.$route.params;
      return situation === 'Novo' ? true : false;
    },

    cannotEdit() {
      const { situation } = this.$route.params;
      return situation === 'Concluído' ? false : true;
    },

    checkMobile() {
      return this.$vuetify.breakpoint.smAndDown ? false : true;
    },

    headers() {
      return [
        {
          href: 'tab-1',
          title: 'Captação de vidas',
          icon: 'fa-solid fa-chalkboard-user',
          component: 'PageLifeCapture',
          visible: true
        },
        {
          href: 'tab-2',
          title: 'Entrega de documentos',
          icon: 'fa-regular fa-folder',
          component: 'PageDocuments',
          visible: true
        },
        {
          href: 'tab-3',
          title: 'Histórico de documentos',
          icon: 'fa-solid fa-list-check',
          component: 'PageHistory',
          visible: !this.newSituation
        },
        {
          href: 'tab-4',
          title: 'Dados da 1º fatura',
          icon: 'fa-solid fa-dollar-sign',
          component: 'PageInvoice',
          visible: checkPermission(['CC1', 'CC2'], '!==')
        },
        {
          href: 'tab-5',
          title: 'Atualização de carteirinhas',
          icon: 'payments',
          component: 'PagePlanCards',
          visible: checkPermission(['CC1', 'CC2'], '!==') && !this.cannotEdit
        },
        {
          href: 'tab-6',
          title: 'Concluir',
          icon: 'fa-solid fa-check',
          component: 'PageFinish',
          visible: checkPermission(['CC1', 'CC2'], '!==') && this.cannotEdit
        }
      ];
    }
  }
};
</script>
